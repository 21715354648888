import React, {Component} from 'react';
import {PropTypes, connect} from '../../util.js';
import {Pagination as PaginationStrap, PaginationItem, PaginationLink} from 'reactstrap';
import {Link} from 'react-router-dom';

const scrollToTop = () => window.scrollTo(0, 0);

class PaginationComponent extends Component {
  static propTypes = {
    device: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']).isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    baseUrl: PropTypes.string.isRequired,
  }

  render() {
    const {pageCount, currentPage, baseUrl} = this.props;
    if (pageCount < 2) {
      return null;
    }

    const maxPagination = this.props.device === 'xs' ? 5 : 10;
    var startPaginationAt = 0;
    var paginationCount = pageCount;
    if (pageCount > maxPagination) {
      startPaginationAt = Math.max(0, currentPage - Math.floor(maxPagination / 2));
      paginationCount = Math.min(maxPagination, pageCount - startPaginationAt);
    }

    return (
      <PaginationStrap className="mx-auto">
        {currentPage > 1 ? (
          <>
            <PaginationItem>
              <PaginationLink first tag={Link} to={baseUrl} onClick={scrollToTop} />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink previous tag={Link} to={baseUrl + `/${currentPage - 1}`} onClick={scrollToTop} />
            </PaginationItem>
          </>
        ) : null}

        {Array.apply(null, {length: paginationCount}).map((_, index) => {
          const thisPageIndex = startPaginationAt + index;
          return (
            <PaginationItem key={thisPageIndex} active={currentPage === thisPageIndex + 1}>
              <PaginationLink tag={Link} to={baseUrl + (thisPageIndex > 0 ? '/' + (thisPageIndex + 1) : '')} onClick={scrollToTop}>
                {thisPageIndex + 1}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        {currentPage < pageCount ? (
          <>
            <PaginationItem>
              <PaginationLink next tag={Link} to={baseUrl + `/${currentPage + 1}`} onClick={scrollToTop} />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink last tag={Link} to={baseUrl + `/${pageCount}`} onClick={scrollToTop} />
            </PaginationItem>
          </>
        ) : null}
      </PaginationStrap>
    );
  }
}

export const Pagination = connect(state => ({device: state.app.device}))(PaginationComponent);
