import React, { Component } from 'react';
import { PropTypes, connect, CategoryHelper } from '../../util.js';
import { ProductIdResolverDecorator } from '../controls.js';
import { CategoryCrumb } from '../controls.js';
import { ProductDetailGallery } from './controls/ProductDetailGallery.jsx';
import { ProductDetailNotices } from './ProductPage/ProductDetailNotices.jsx';
import { ProductPrice } from './ProductPage/ProductPrice.jsx';
import { Menu } from '../skeleton/Menu.jsx';


const CoronaDeliveryOptions = ({ t }) => {
  return (
    <div className="product-group">
      <h4>{t('corona.order')}</h4>
      <hr />
      <p style={{ fontWeight: 'bold', fontSize: '1.3em', marginBottom: 45 }}>
        <a href="mailto:info@stockoma.be"><i className="fa fa-envelope" style={{ paddingRight: 12 }} /></a>
        {t('corona.mailUs')}
      </p>
    </div>
  );
};


CoronaDeliveryOptions.propTypes = {
  t: PropTypes.any.isRequired
};

const notAllowedToOrder = [
  '5a2ae6a56a0de608c87c7559',
  '5ea967691358ba123888b2f7',
  '5a2ae6a56a0de608c87c7563',
  '5a2ae6a56a0de608c87c7562',
  '5a2ae6a56a0de608c87c7564',
  '5ea971341358ba123888d480',
  '5a2ae6a56a0de608c87c7561',
  '5a2ae6a56a0de608c87c755c',
  '5a2ae6a56a0de608c87c755e',
  '5a2ae6a56a0de608c87c755b',
  '5a2ae6a56a0de608c87c755a'
];




class ProductPageComponent extends Component {
  static propTypes = {
    deliveryAvailable: PropTypes.bool.isRequired,
    device: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']).isRequired,
    category: PropTypes.CategoryModel.isRequired,
    product: PropTypes.ProductModel.isRequired,
    t: PropTypes.any.isRequired
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // TODO: add a product pageview to google analytics
    // const prod = this.props.product;
    // httpClient.post('/api/products/Views/' + prod.id);
  }

  render() {
    const { product, category, device, t } = this.props;

    const ProductPageContent = (
      <div className="product-page">
        <h1>{product.name}</h1>
        <ProductPrice price={product.price} style={{ marginTop: -10, marginBottom: 8 }} />
        <div className="row" style={{marginRight: 0, marginLeft: 0}}>
          {product.imageCount ? (
            <div className="col-md-5 product-gallery">
              <ProductDetailGallery product={product} />
            </div>
          ) : null}
          <div className={product.imageCount ? 'col-md-7' : undefined}>
            {product.desc ? (
              <div className="product-group product-desc">
                <h4>{t('product.description')}</h4>
                <hr />
                {product.desc.split('\n').map((desc, index) => <p key={index} dangerouslySetInnerHTML={{ __html: desc }} />)}
              </div>
            ) : null}

            <ProductSpecifications product={product} />
            {(!notAllowedToOrder.includes(category.id) && this.props.deliveryAvailable) &&
              <CoronaDeliveryOptions t={t} />
            }
          </div>
          <ProductDetailNotices className="col-12 col-sm-12 product-notices" />
        </div>
      </div>
    );





    if (device !== 'xs' && device !== 'sm') {
      return (
        <Menu category={category}>
          {ProductPageContent}
        </Menu>
      );
    }

    return (
      <div className="product-page-container">
        <CategoryCrumb category={category} showFull />
        {ProductPageContent}
      </div>
    );
  }
}

const mapStateToProps = state => ({deliveryAvailable: state.app.deliveryAvailable, device: state.app.device, cats: state.cats, t: state.t });
export const ProductPage = connect(mapStateToProps)(ProductIdResolverDecorator(ProductPageComponent));




class ProductSpecificationsComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    cats: PropTypes.CategoryModelList.isRequired,
    prods: PropTypes.ProductModelList.isRequired,
    product: PropTypes.ProductModel.isRequired,
  }

  render() {
    const { t, product } = this.props;
    if (!product.specs) {
      return null;
    }

    const catsHelper = new CategoryHelper(this.props.cats, this.props.prods);
    const specs = catsHelper.getSpecs(product);

    return (
      <div className="product-group">
        <h4>{t('product.specifications')}</h4>
        <hr />
        <p>{specs.map((spec, index) => <ProductSpec key={index} spec={spec} />)}</p>
      </div>
    );
  }
}

const ProductSpecifications = connect(state => ({ t: state.t, cats: state.cats, prods: state.prods }))(ProductSpecificationsComponent);


class ProductSpec extends Component {
  static propTypes = {
    spec: PropTypes.any.isRequired,
  }

  render() {
    const { spec } = this.props;
    return (
      <span className="spec-group">
        <span className="spec-title">{spec.name}:</span>
        {spec.options.map((option, index) => {
          return (
            <span key={option}>
              {option}
              {index !== spec.options.length - 1 ? ', ' : null}
            </span>
          );
        })}
      </span>
    );
  }
}
